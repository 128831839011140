<template>
    <v-row class="pl-5 pr-10 pt-5">
      <v-col cols="12" md="4">
      <div class="text-h4 font-weight-bold natural--text text--darken-4">{{title}}</div>
      <div class="text-h5 natural--text text--darken-4">{{subtitle}}</div>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="auto" align-self="center">
           <slot name="action"></slot>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'เนื้อหา Title'
    },
    subtitle: {
      type: String,
      default: 'เนื้อหา Subtitle'
    }
  }

}
</script>

<style>

</style>
