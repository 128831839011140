<template>

       <label class="text-title-4 natural--text text--darken-4">{{title}} <span v-if="$attrs.required" class="error--text">*</span>
       <!-- </div> -->
        <v-select
        outlined
        v-bind="$attrs"
        v-on="inputListeners"
        >
           <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-select>
       </label>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    line-height: 18px;
    max-width: 100%;
    min-width: 0;
    border-right: 1px solid;
    border-color: #DBDCE7;
}
::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 11px 0;
}
.v-select.v-text-field--outlined ::v-deep fieldset {
  border-color: #DBDCE7;
}
</style>
