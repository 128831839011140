<template>
  <v-container fluid>
     <TitleBar :title="'รายงานภาพรวม'" :subtitle="'0 รายงาน'">
      <template slot="action">
         <!--  <v-btn x-large color="primary"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างแบบฟอร์ม</span></v-btn> -->
      </template>
    </TitleBar>
    <FilterBar>
      <template slot="left">
         <v-col cols="12" md="3" >
           <Select :title="'หน่วยบริการ'" :placeholder="'โปรดเลือก'" v-model="site" :items="items" item-text="siteName" return-object/>
         </v-col>
      </template>
      <template slot="right">
      </template>
    </FilterBar>
    <v-row class="pl-5 pr-10">
      <v-col cols="12">
        <v-card height="500">
      <AzureMap  class="azure-map"  :center="[long, lat]" :zoom="zoom" @ready="setMap">
        <AzureMapDataSource>
      <AzureMapPoint :longitude="long" :latitude="lat"/>
      <AzureMapSymbolLayer :options="targetSymbolLayerOptions"/>
    </AzureMapDataSource>
     </AzureMap>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import Select from '@/components/common/Select'
import FilterBar from '@/components/common/FilterBar'
import { getSites } from '@/api/'
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer
} from 'vue-azure-maps'

export default {
  components: {
    TitleBar,
    Select,
    FilterBar,
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer
  },
  data () {
    return {
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createby', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'status', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      loading: true,
      items: [],
      site: null,
      lat: 13.806432,
      long: 100.523422,
      map: null,
      zoom: 14,
      targetSymbolLayerOptions: {
        iconOptions: {
          image: 'pin-red'
        }
      }
    }
  },
  methods: {
    setMap (mapEvent) {
      this.map = mapEvent.map
      this.map.resize()
    },
    fetchData () {
      this.loading = true
      if (this.$auth.role) {
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getSites({
          tenantId: tenant.tenantId,
          siteId: '',
          siteName: ''
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              this.items = message.data.result.items
            } else {
              this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }

}
</script>

<style  scoped>
.azure-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
