<template>
   <v-row class="pl-5 pr-10 pt-5">
      <v-col cols="12">
        <v-card color="natural lighten-4" >
          <v-card-text>
            <v-row >
                <slot name="left"></slot>
              <v-spacer/>
                <slot name="right"></slot>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
     </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>
